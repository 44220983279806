import React, { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationContext } from "./LocalizationContext";

const LanguagePicker = () => {
  const { setAppLanguage, appLanguage } = useContext(LocalizationContext);

  return (
    <div>
      <div
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={appLanguage}
          label="Age"
          onChange={(value) => {
            console.log("value==", value);
            setAppLanguage(value.target.value);
          }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="kn">हिन्दी</MenuItem>
        </Select>
      </div>
    </div>
  );
};

export default LanguagePicker;
