import LocalizedStrings from 'react-localization';

import en from './en';
import kn from './kn-IN';
export const DEFAULT_LANGUAGE = 'en';


const translations = {
  en: en,
  kn: kn,
};

export default new LocalizedStrings(translations);
