// import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
import { Box, Container, Grid, Link } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SocialMedia from './common.js/SocialMedia';
import { useContext } from 'react';
import { LocalizationContext } from '../../locales/LocalizationContext';

export default function Footer() {
  const {translations} = useContext(LocalizationContext)
  const {help, contact, support, privacy, account, login, register, messages, backup, history } = translations
  return (
    <footer>
      <Box
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        bgcolor="text.secondary"
        color="white"
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>{help}</Box>
              <Box>
                <Link href="/" color="inherit">
                  {contact}
                </Link>
              </Box>
              <Box>
                <Link href="/" color="inherit">
                  {support}
                </Link>
              </Box>
              <Box>
                <Link href="/" color="inherit">
                  {privacy}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>{account}</Box>
              <Box>
                <Link href="/auth/login" color="inherit">
                  {login}
                </Link>
              </Box>
              <Box>
                <Link href="/auth/register" color="inherit">
                  {register}
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>{messages}</Box>
              <Box>
                <Link href="/" color="inherit">
                  {backup}
                </Link>
              </Box>
              <Box>
                <Link href="/" color="inherit">
                  {history}
                </Link>
              </Box>
              <Box>
                <Link href="/" color="inherit">
                  Roll
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" sx={{marginLeft: "45%"}}>
          <Grid item >
            <SocialMedia link="" icon={<FacebookRoundedIcon color='primary'/>}/>
            </Grid>
            <Grid item >
            <SocialMedia link="" icon={<InstagramIcon color='primary' />}/>
            </Grid>
            <Grid item >
            <SocialMedia link="" icon={<LinkedInIcon color='primary'/>}/>
            </Grid>
            <Grid item >
            <SocialMedia link="" icon={<TwitterIcon color='primary' />}/>
            </Grid>
          </Grid>
          <Box textAlign="center" sx={{marginTop: "2%"}}>
            onclouditservices.com <br />
          Copyright &copy;; {new Date().getFullYear()}
          </Box>
        </Container>
      </Box>
    </footer>
  );
}