import './Dashboard.css'
import MainDash from './MainDash/MainDash';
import RightSide from './RigtSide/RightSide';
import Sidebar from './Sidebar';

function Dashboard() {
  return (
    <div className="Dashboard">
      <div className="DashboardGlass">
        <Sidebar/>
        <MainDash/>
        {/* <RightSide /> */}
      </div>
    </div>
  );
}

export default Dashboard;
