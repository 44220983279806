import React, {useEffect} from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import {clientId} from '../../config/google'

const GoogleSignIn = (props) => {
useEffect(() => {
   const initClient = () => {
         gapi.client.init({
         clientId: clientId,
         scope: ''
       });
    };
    gapi.load('client:auth2', initClient);
}); 
const onSuccess = (res) => {
    console.log('success:', props.history.location, res);
};
const onFailure = (err) => {
    console.log('failed:', err);
};
return (
   <GoogleLogin
      clientId={clientId}
      buttonText="Sign in with Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
  />)
}
 
export default GoogleSignIn;