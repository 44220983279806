const en = {
  welcome: "welcome",
  bring_your: "Bring your",
  business_online: "business online",
  with: "with",
  bottom_line_sub: "Cloud IT Services is providing online softwares, which are scalable and customizable as per your business",
  home: "Home",
  products: "Products",
  blog: "blog",
  pricing: "Pricing",
  search: "Search",
  help: "Help",
  contact: "Contact",
  support: "Support",
  privacy: "Privacy",
  account: "Account",
  login: "Login",
  register: "Register",
  messages: "Messages",
  backup: "Backup",
  history: "History"

};
  
  export default en;
  