import React, { Fragment, Suspense, lazy } from "react";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./components/landing-page";
import SignIn from "./components/signin/SignIn";
import SignUp from "./components/signin/SignUp";
import { LOGIN_PATH, SCHOOL_PATH_HOME, SIGNUP_PATH } from "./components/constants";
import SchoolDashboard from "./components/school/Dashboard";
import DashboardDefault from "./components/school/Dashboard";
import './App.css'

function App() {
  return (
    <>
    <BrowserRouter>
    <StyledEngineProvider injectFirst>
    {/* <ThemeProvider theme={theme}> */}
      <CssBaseline/>
    <Routes>
      <Route path="/" element={<LandingPage/>} />
      <Route path={LOGIN_PATH} element={<SignIn />} />
      <Route path={SIGNUP_PATH} element={<SignUp />} />
      {/* <Route path={SIGNUP_PATH} element={<SignUp />} /> */}
      <Route path={SCHOOL_PATH_HOME} element={< DashboardDefault/>} />
    </Routes>
    {/* </ThemeProvider> */}
    </StyledEngineProvider>
  </BrowserRouter>
    </>
  );
}

export default App;