const kn = {
  welcome: "स्वागत",
  bring_your: "अपना व्यवसाय",
  business_online: "ऑनलाइन लाएं",
  with: "विथ",
  bottom_line: "के साथ अपना व्यवसाय ऑनलाइन लाएं",
  bottom_line_sub: "क्लाउड आईटी सेवाएं ऑनलाइन सॉफ्टवेयर प्रदान कर रही हैं, जो आपके व्यवसाय के अनुसार मापनीय और अनुकूलन योग्य हैं",
  home: "होम",
  products: "उत्पादों",
  blog: "ब्लॉग",
  pricing: "मूल्य निर्धारण",
  search: "खोज करो",
  help: "हेल्प",
  contact: "संपर्क",
  support: "सहायता",
  privacy: "गोपनीयता",
  account: "एकाउंट",
  login: "लॉग इन करें",
  register: "पंजीकरण करें",
  messages: "संदेशों",
  backup: "बैकअप",
  history: "इतिहास"
};
  
  export default kn;
  