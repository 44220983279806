import React, { Component, useEffect } from 'react';
import Appbar from "./Appbar";
import {  Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Paper, Typography } from "@mui/material";
import main from "../../assets/many-lap.jpg"
import Footer from "./Footer";
import Pricing from "./Pricing";
import Product from "../product/Product";
import axios from "axios"
import { useContext } from 'react';
import { LocalizationContext } from '../../locales/LocalizationContext';

function LandingPage() {
  const {translations} = useContext(LocalizationContext)
  const {bring_your, business_online, bottom_line_sub} = translations
  useEffect(() => {
    axios.get("https://clouditservices.herokuapp.com/api/items").then((response) => {
      console.log("data==>", response.data);
    });
  }, [])
  return (
    <div>
      <Appbar />
      <Container fixed sx={{ bgcolor: "transparent" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper sx={{marginTop: "20%"}} elevation={0}>
                <Typography variant="h2" sx={{color: "#212B36", fontWeight: "bold", lineHeight: "137%", marginTop: "6%"}}>
                {bring_your} <br /> 
                {business_online} <br />
                with <span style={{color: "red"}}>CISS</span>
                </Typography>
                <Typography variant="h5" sx={{fontFamily: "Poppins,sans-serif", color: "#637381", fontWeight: 400, marginTop: "7%"}}>
                    {bottom_line_sub}.
                </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{marginTop: "12% "}} elevation={0}>
              <img src={main} alt="loading...." height="100%" width="100%" style={{marginTop: "10%"}}/>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Product />
      <Pricing/>
      <Footer />
    </div>
  );
}

export default LandingPage;
