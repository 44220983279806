import React, { Component } from 'react';
import Dashboard from '../dashboard/components/Dashboard';
// import Dashboard from '../dashboard/Dashboard';
 

class SchoolDashboard extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (  <Dashboard />  );
    }
}
 
export default SchoolDashboard;