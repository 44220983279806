import { Link } from "@mui/material";
import React from "react";

const SocialMedia = (props) => {
  return (
    <Link href={props.link} color="inherit">
      {props.icon}
    </Link>
  );
};

export default SocialMedia;
